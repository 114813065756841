export enum EventSubscriptionType {
  CheckInAlert = "checkInAlert",
}

export interface EventSubscriptionOptions {
  event: EventSubscriptionType;
  alertLevel?: number;
}

export interface EventSubscription {
  id: string;
  teamId: string;
  event: string;
  options: EventSubscriptionOptions;
  orgId: string;
  communicationGroupName: string;
  communicationGroupId: string;
}

export interface EventSubscriptionApiResponse {
  id: string;
  teamId: string;
  event: EventSubscriptionType;
  options: EventSubscriptionOptions;
  orgId: string;
  communicationGroupName: string;
  communicationGroupId: string;
}
