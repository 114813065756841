import { withTheme } from "@emotion/react";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { useEffect } from "react";
import { getCommunicationGroup } from "../../../communications/slices/communicationGroupsSlice";
import Modal from "../../../../components/Modal";
import { Box, Grid, Typography } from "@mui/material";
import useAppSelector from "../../../../hooks/useAppSelector";
import { LoadingStatus } from "../../../../models/loadingStatus";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";
import AgGridTable from "../../../../components/AgGridTable";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { FlatCommunication } from "../../../communications/models/communication";

type CommunicationGroupDetailsModalProps = {
  id: string;
  show: boolean;
  onClose: () => void;
};

function CommunicationGroupDetailsModal({
  id,
  show,
  onClose,
}: CommunicationGroupDetailsModalProps) {
  const dispatch = useAppDispatch();

  const communicationGroup = useAppSelector(
    (state) => state.communicationGroups.communicationGroup
  );
  const communicationGroupStatus = useAppSelector(
    (state) => state.communicationGroups.getStatus
  );

  useEffect(() => {
    dispatch(getCommunicationGroup(id));
  }, [id, dispatch]);

  const columns = [
    {
      field: "translatedIdentifier",
      headerName: "User/Email",
      sortable: true,
      flex: 1,
      cellRenderer: (
        params: ICellRendererParams<FlatCommunication, string>
      ) => {
        if (params.data?.type === "email" && !params.value) {
          return params.data.identifier;
        }
        return params.value;
      },
    },
    {
      field: "type",
      headerName: "Communication Type",
      flex: 1,
      sortable: true,
    },
  ] as ColDef[];

  if (communicationGroupStatus !== LoadingStatus.succeeded) {
    return (
      <LoadingStatusIndicator loadingStatuses={[communicationGroupStatus]} />
    );
  }

  return (
    <Modal
      showModal={show}
      headerText={"Communication Group Details"}
      onClose={onClose}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        overflow: "auto",
        minWidth: "50vw",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component={"div"}>
            <Box fontWeight="fontWeightMedium" display="inline" fontSize={16}>
              {`Name: `}
            </Box>
            <Box display="inline" fontSize={16}>
              {communicationGroup?.name}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component={"div"}>
            <Box fontWeight="fontWeightMedium" display="inline" fontSize={16}>
              {`Description: `}
            </Box>
            <Box display="inline" fontSize={16}>
              {communicationGroup?.description}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component={"div"} style={{ overflow: "hidden" }}>
            <Box fontWeight="fontWeightMedium" display="inline" fontSize={16}>
              {`Is Active: `}
            </Box>
            <Box display="inline" fontSize={16}>
              {communicationGroup?.isActive === true ? "Yes" : "No"}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} minHeight={"40vh"} width={"100%"}>
          <AgGridTable
            data={communicationGroup!.communications.filter(
              (el) => el.type !== "slack"
            )}
            columns={columns}
            dataCyTag={"communicationsTable"}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}

export default withTheme(CommunicationGroupDetailsModal);
