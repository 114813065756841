import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { importMixedTasksCsv } from "../../users/services/tasksCsvService";
import { uploadFileToFileStorage } from "../../../services/s3ClientStorageService";

type TasksCsvSliceState = {
  status: LoadingStatus;
  error: string;
};

const initialState: TasksCsvSliceState = {
  status: LoadingStatus.idle,
  error: "",
};

export type UploadMixedTasksCsvRequest = {
  name: string;
  orgId: string;
  headline: string;
  cadence: string;
  taxonomy?: string;
  visibility?: string;
  file: File;
  contentCsvImportId?: string;
  reviewWindowInHours?: number;
  requiredReviewCount?: number;
  reviewType: string;
};

export const uploadMixedTasksCsv = createAsyncThunk(
  "mixedTasksCsvFile/upload",
  async (request: UploadMixedTasksCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );
    } catch (err: any) {
      console.warn(err);
      return rejectWithValue({
        error: err,
      });
    }

    request.contentCsvImportId = importId;
    try {
      var response = await importMixedTasksCsv(request);
      return {
        data: response.data,
        meta: {},
      };
    } catch (err: any) {
      console.warn(err);
      return rejectWithValue({
        error: err,
      });
    }
  }
);

const tasksCsvSlice = createSlice({
  name: "tasksCsv",
  initialState,
  reducers: {
    clearUploadMixedTasksCsv: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadMixedTasksCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(uploadMixedTasksCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(uploadMixedTasksCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { clearUploadMixedTasksCsv } = tasksCsvSlice.actions;

export default tasksCsvSlice.reducer;
