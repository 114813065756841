export const capabilitiesSwitchStyle = {
  borderRadius: 2,
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "primary",
    "& .MuiSwitch-thumb": {
      border: "1px solid primary",
    },
  },
  "& .MuiSwitch-thumb": {
    border: "1px solid gray",
  },
};
