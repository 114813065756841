import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";

import * as accreditationSpecService from "../services/accreditationSpecService";
import { AccreditationSpec } from "../models/accreditationSpec";

export type QueryAccreditationSpecsRequest = {
  orgId: string;
  id?: number;
  name?: string;
  code?: string;
};

export const queryAccreditationSpecs = createAsyncThunk(
  "accreditation-specs/query",
  async (request: QueryAccreditationSpecsRequest, { rejectWithValue }) => {
    try {
      return (
        await accreditationSpecService.queryAccreditationSpecsAsync(request)
      ).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const getAccreditationSpec = createAsyncThunk(
  "accreditation-specs/get",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await accreditationSpecService.getAccreditationSpecAsync(id))
        .data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface AccreditationSpecState {
  accreditationSpecs: AccreditationSpec[];
  accreditationSpec?: AccreditationSpec;
  status: LoadingStatus;
  error: string;
}

const initialState: AccreditationSpecState = {
  accreditationSpecs: [],
  status: LoadingStatus.idle,
  error: "",
};

const accreditationSpecsSlice = createSlice({
  name: "accreditationSpecs",
  initialState,
  reducers: {
    clearAccreditationSpec: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryAccreditationSpecs.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(queryAccreditationSpecs.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditationSpecs: action.payload.data,
      };
    });
    builder.addCase(queryAccreditationSpecs.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        accreditationSpecs: [],
        error: "Failed to query accreditation specs",
      };
    });
    builder.addCase(getAccreditationSpec.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getAccreditationSpec.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditationSpec: action.payload.data,
      };
    });
    builder.addCase(getAccreditationSpec.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get accreditation spec",
      };
    });
  },
});

export default accreditationSpecsSlice.reducer;

export const { clearAccreditationSpec } = accreditationSpecsSlice.actions;
