import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  CourseEnrolmentStat,
  CourseActivity,
  CourseUnitActivity,
} from "../models/learningStats";
import {
  getCourseEnrolmentsAsync,
  getCourseActivitiesAsync,
  getCourseUnitActivitiesAsync,
} from "../services/learningStatsService";

export interface GetCourseActivitiesRequest {
  orgId: string;
  timeRange: string;
}

export const getCourseActivities = createAsyncThunk(
  "learning-stats/getCourseActivities",
  async (request: GetCourseActivitiesRequest, { rejectWithValue }) => {
    try {
      const getCourseActivitiesResponse = await getCourseActivitiesAsync(
        request
      );

      return getCourseActivitiesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export interface GetCourseUnitActivitiesRequest {
  orgId: string;
  timeRange: string;
  courseSpecId: string;
}

export const getCourseUnitActivities = createAsyncThunk(
  "learning-stats/getCourseUnitActivities",
  async (request: GetCourseUnitActivitiesRequest, { rejectWithValue }) => {
    try {
      const getCourseActivitiesResponse = await getCourseUnitActivitiesAsync(
        request
      );

      return getCourseActivitiesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export interface GetCourseEnrolmentsRequest {
  orgId: string;
  timeRange: string;
  courseSpecId: string;
}

export const getCourseEnrolments = createAsyncThunk(
  "learning-stats/getCourseEnrolments",
  async (request: GetCourseEnrolmentsRequest, { rejectWithValue }) => {
    try {
      const getCourseEnrolmentsResponse = await getCourseEnrolmentsAsync(
        request
      );

      return getCourseEnrolmentsResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface LearningStatsState {
  courseActivities: CourseActivity[];
  courseUnitActivities: CourseUnitActivity[];
  courseEnrolments: CourseEnrolmentStat[];
  status: LoadingStatus;
  error: string;
}

const initialState: LearningStatsState = {
  courseActivities: [],
  courseUnitActivities: [],
  courseEnrolments: [],
  status: LoadingStatus.idle,
  error: "",
};

const learningStatsSlice = createSlice({
  name: "learningStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourseActivities.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getCourseActivities.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        courseActivities: action.payload.data,
      };
    });
    builder.addCase(getCourseActivities.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        courseActivities: [],
        error: "Failed to get course activities.",
      };
    });

    builder.addCase(getCourseUnitActivities.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getCourseUnitActivities.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        courseUnitActivities: action.payload.data,
      };
    });
    builder.addCase(getCourseUnitActivities.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        courseUnitActivities: [],
        error: "Failed to get course unit activities.",
      };
    });

    builder.addCase(getCourseEnrolments.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getCourseEnrolments.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        courseEnrolments: action.payload.data,
      };
    });
    builder.addCase(getCourseEnrolments.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        courseEnrolments: [],
        error: "Failed to get course enrolments.",
      };
    });
  },
});

export default learningStatsSlice.reducer;
