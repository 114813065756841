import styled from "@emotion/styled";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  Grid,
  MenuItem,
  Select as MuiSelect,
  Switch,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAppSelector from "../../../hooks/useAppSelector";
import { LoadingStatus } from "../../../models/loadingStatus";
import { useCurrentOrg, useCurrentUser } from "../../user/hooks/userHooks";
import { DropEvent, useDropzone } from "react-dropzone";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import {
  clearUploadMixedTasksCsv,
  uploadMixedTasksCsv,
} from "../slices/tasksCsvSlice";
import {
  ReviewType,
  TaskSpecCadence,
  TaskVisibility,
} from "../models/taskSpecEnums";
import { capitalize } from "lodash";
import { InsertDriveFile } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import LoadingStatusIndicator from "../../../components/LoadingStatusIndicator";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Select = styled(MuiSelect)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

function ImportChallengeSet() {
  const currentOrg = useCurrentOrg();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const uploadCsvStatus = useAppSelector((state) => state.tasksCsv.status);

  const [file, setFile] = React.useState<File | null>();
  const [reviewWindow, setReviewWindow] = React.useState(24);
  const handleDropAccepted = async (files: File[], event: DropEvent) => {
    setFile(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/text": [".csv"],
    },
    maxFiles: 1,
    multiple: false,
    onDropAccepted: handleDropAccepted,
  });

  useEffect(() => {
    dispatch(clearUploadMixedTasksCsv());
  }, [dispatch]);

  if (uploadCsvStatus === LoadingStatus.loading) {
    return <LoadingStatusIndicator loadingStatuses={[uploadCsvStatus]} />;
  }

  const onFrequencyUpdate = (value: TaskSpecCadence) => {
    var defaultReviewWindow = 24;
    switch (value) {
      case TaskSpecCadence.weekly:
        defaultReviewWindow = 168;
        break;
      case TaskSpecCadence.daily:
      default:
        defaultReviewWindow = 24;
        break;
    }

    setReviewWindow(defaultReviewWindow);
  };

  const mapReviewMethodToInt = (reviewMethod: string) => {
    switch (reviewMethod) {
      case ReviewType.multi:
        return 3;
      case ReviewType.single:
      case ReviewType.hierarchical:
        return 1;
      default:
        return 3;
    }
  };

  return (
    <>
      <Helmet title="Import Challenge Set (CSV upload)" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        data-cy="importChallengerSetHeader"
      >
        Import Challenge Set (CSV upload)
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6} mb={5}>
        <Grid item xs>
          <Typography variant="h4" gutterBottom display="inline">
            Upload Challenge Set for {currentOrg?.name}
          </Typography>
        </Grid>
      </Grid>
      <Formik
        initialValues={{
          challengeName: "",
          challengeDescription: "",
          challengeFrequency: TaskSpecCadence.daily,
          reviewMethod: ReviewType.single,
          shareToChallengeLibrary: false,
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          challengeName: Yup.string().required("Required"),
          challengeDescription: Yup.string().required("Required"),
          challengeFrequency: Yup.string().required("Required"),
          reviewMethod: Yup.string().required("Required"),
          shareToChallengeLibrary: Yup.bool().required("Required"),
        })}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            if (file && currentOrg && user) {
              await dispatch(
                uploadMixedTasksCsv({
                  file: file!,
                  name: values.challengeName,
                  orgId: currentOrg?.id,
                  headline: values.challengeDescription,
                  cadence: values.challengeFrequency,
                  visibility:
                    user?.orgs?.find((o) => o.id === currentOrg?.id)
                      ?.featureFlags?.canMakeTemplatePublic === true
                      ? values.shareToChallengeLibrary
                        ? TaskVisibility.public
                        : TaskVisibility.org
                      : undefined,
                  reviewWindowInHours: reviewWindow,
                  requiredReviewCount: mapReviewMethodToInt(
                    values.reviewMethod
                  ),
                  reviewType: values.reviewMethod,
                })
              )
                .unwrap()
                .then(() => {
                  const snackbarKey = enqueueSnackbar(
                    `${values.challengeName} imported`,
                    {
                      variant: "success",
                      onClick: () => closeSnackbar(snackbarKey),
                    }
                  );
                });

              setFile(null);
            }
          } catch (error: any) {
            const message =
              error.error.response.data?.meta?.errorMessage ||
              "Something went wrong";

            setStatus({ success: false });
            const snackbarKey = enqueueSnackbar(message, {
              variant: "error",
              onClick: () => closeSnackbar(snackbarKey),
            });
            setSubmitting(false);
            setFile(null);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          dirty,
        }) => (
          <Box>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" m={10}>
                <CircularProgress />
              </Box>
            ) : (
              <Form noValidate>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <FormControl fullWidth>
                          <Typography
                            variant="h6"
                            pl={4}
                            mt={2}
                            fontWeight="bold"
                          >
                            Challenge name
                          </Typography>
                          <Typography component="h1" variant="subtitle2" pl={4}>
                            Specify how the challenge will be named within the
                            web app
                          </Typography>
                          <TextField
                            name="challengeName"
                            value={values.challengeName}
                            error={Boolean(
                              touched.challengeName && errors.challengeName
                            )}
                            fullWidth
                            helperText={
                              touched.challengeName && errors.challengeName
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                            data-cy="challengeNameInput"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography
                            variant="h6"
                            pl={4}
                            mt={2}
                            fontWeight="bold"
                          >
                            Challenge description
                          </Typography>
                          <Typography component="h1" variant="subtitle2" pl={4}>
                            Specify how the challenge will be described within
                            the mobile app
                          </Typography>
                          <TextField
                            name="challengeDescription"
                            value={values.challengeDescription}
                            error={Boolean(
                              touched.challengeDescription &&
                                errors.challengeDescription
                            )}
                            fullWidth
                            helperText={
                              touched.challengeDescription &&
                              errors.challengeDescription
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                            data-cy="challengeDescriptionInput"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography
                            variant="h6"
                            pl={4}
                            mt={2}
                            fontWeight="bold"
                          >
                            Challenge frequency
                          </Typography>
                          <Typography component="h1" variant="subtitle2" pl={4}>
                            Specify how the challenge will be completed via the
                            mobile app
                          </Typography>
                          <Select
                            labelId="challengeFrequency-select-label"
                            name="challengeFrequency"
                            value={values.challengeFrequency}
                            onChange={(value) => {
                              const newValue = value.target
                                .value as TaskSpecCadence;
                              values.challengeFrequency = newValue;
                              onFrequencyUpdate(newValue);
                            }}
                            error={Boolean(
                              touched.challengeFrequency &&
                                errors.challengeFrequency
                            )}
                            fullWidth
                            onBlur={handleBlur}
                            label="challengeFrequency"
                            my={2}
                          >
                            {Object.values(TaskSpecCadence).map((option) => (
                              <MenuItem key={option} value={option}>
                                {capitalize(option)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography
                            variant="h6"
                            pl={4}
                            mt={2}
                            fontWeight="bold"
                          >
                            Review method
                          </Typography>
                          <Typography component="h1" variant="subtitle2" pl={4}>
                            Specify how submitted challenges should be reviewed
                          </Typography>
                          <Select
                            labelId="reviewMethod-select-label"
                            name="reviewMethod"
                            value={values.reviewMethod}
                            onChange={handleChange}
                            error={Boolean(
                              touched.reviewMethod && errors.reviewMethod
                            )}
                            fullWidth
                            onBlur={handleBlur}
                            label="reviewMethod"
                            my={2}
                          >
                            {Object.values(ReviewType)
                              .filter((x) =>
                                currentOrg?.featureFlags
                                  ?.canUseHierarchicalReviews === true
                                  ? x.toString() !== ""
                                  : x.toString() !== "" &&
                                    x !== ReviewType.hierarchical
                              )
                              .map((option) => (
                                <MenuItem key={option} value={option}>
                                  {capitalize(`${option} review`)}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {currentOrg?.featureFlags?.canMakeTemplatePublic ===
                          true && (
                          <FormControl fullWidth>
                            <Typography
                              variant="h6"
                              pl={4}
                              mt={2}
                              fontWeight="bold"
                            >
                              Shared to challenge library
                            </Typography>
                            <Switch
                              checked={values.shareToChallengeLibrary ?? true}
                              onChange={handleChange}
                              name="shareToChallengeLibrary"
                            />
                          </FormControl>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card
                      style={{
                        height: "100%",
                        minHeight: "40vh",
                        justifyContent: "space-around",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent
                        pb={1}
                        {...getRootProps()}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          {...getInputProps()}
                          data-cy="taskSpecDefinitionFileInput"
                        />
                        {file ? (
                          <InsertDriveFile
                            sx={{ fontSize: "180px" }}
                            color="primary"
                          />
                        ) : (
                          <Typography variant="h4" textAlign="center">
                            Drag and drop some files here, or click to select
                            files
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container mt={2}>
                  <Grid item xs display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !dirty || !file}
                      mt={3}
                      data-cy="formSubmitButton"
                    >
                      Save changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}

export default ImportChallengeSet;
