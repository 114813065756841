import useAuth from "../../../../hooks/useAuth";

interface CanViewPracticalReviewsNavItemGuardProps {
  children?: React.ReactNode;
}

const CanViewPracticalReviewsNavItemGuard = ({
  children,
}: CanViewPracticalReviewsNavItemGuardProps) => {
  const { isVisiblyPracticalReviewer } = useAuth();

  if (!isVisiblyPracticalReviewer) {
    return <></>;
  }

  return <>{children}</>;
};

export default CanViewPracticalReviewsNavItemGuard;
