import { AxiosPromise } from "axios";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import {
  Accreditation,
  AccreditationAdditionalInfo,
} from "../models/accreditation";
import { QueryAccreditationRequest } from "../slices/accreditationSlice";

type ClaimAccreditationResponse = {
  meta: ApiResponseMeta;
};

type ClaimAccreditationRequest = {
  orgId: string;
  userId: string;
  accreditationSpecId: number;
  expiresAt: string;
  additionalInfo?: {};
};

type QueryAccreditationResponse = {
  data: Accreditation[];
  meta: ApiResponseMeta;
};

type RevokeAccreditationResponse = {
  meta: ApiResponseMeta;
};

type ApproveAccreditationRequest = {
  id: string;
  orgId: string;
  additionalInfo?: AccreditationAdditionalInfo;
};

type ApproveAccreditationResponse = {
  meta: ApiResponseMeta;
};

async function claimAccreditationAsync(
  request: ClaimAccreditationRequest
): Promise<AxiosPromise<ClaimAccreditationResponse>> {
  return await axios.post(`/accreditations/v1/claim`, request);
}

async function revokeAccreditationAsync(
  id: string
): Promise<AxiosPromise<RevokeAccreditationResponse>> {
  return await axios.post(`/accreditations/v1/${id}/revoke`);
}

async function approveAccreditationAsync(
  request: ApproveAccreditationRequest
): Promise<AxiosPromise<ApproveAccreditationResponse>> {
  return await axios.post(`/accreditations/v1/${request.id}/approve`, request);
}

async function queryAccreditationAsync(
  request: QueryAccreditationRequest
): Promise<AxiosPromise<QueryAccreditationResponse>> {
  return await axios.post("/accreditations/v1/query", request);
}

export {
  claimAccreditationAsync,
  queryAccreditationAsync,
  revokeAccreditationAsync,
  approveAccreditationAsync,
};
