import styled from "@emotion/styled";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid,
  Link as MuiLink,
  Typography,
  Divider as MuiDivider,
  capitalize,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { NavLink, useParams } from "react-router-dom";
import EventSubscriptionForm from "./EventSubscriptionsForm";
import useAppSelector from "../../../../hooks/useAppSelector";
import { RootState } from "../../../../redux/store";
import { LoadingStatus } from "../../../../models/loadingStatus";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {
  clearEventSubscription,
  getEventSubscription,
} from "../../slices/eventSubscriptionsSlice";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type EditEventSubscriptionProps = {
  type: "create" | "update";
};

function EditEventSubscription({ type }: EditEventSubscriptionProps) {
  const dispatch = useAppDispatch();
  const { teamId, id } = useParams();

  const [teamName, setTeamName] = useState<string | undefined>();

  const eventSubscription = useAppSelector(
    (state: RootState) => state.eventSubscriptions.eventSubscription
  );
  const eventSubscriptionsStatus = useAppSelector(
    (state: RootState) => state.eventSubscriptions.status
  );

  const team = useAppSelector((state) => state.teams.team);

  useEffect(() => {
    if (id && type === "update") {
      dispatch(getEventSubscription(id));
    } else {
      dispatch(clearEventSubscription());
    }

    if (team && team.id === teamId) {
      setTeamName(team.name);
    }
  }, [teamId, id, dispatch]);

  if (
    type === "update" &&
    eventSubscriptionsStatus !== LoadingStatus.succeeded
  ) {
    return (
      <LoadingStatusIndicator loadingStatuses={[eventSubscriptionsStatus]} />
    );
  }

  return (
    <>
      <Helmet
        title={`${capitalize(type)} Event Subscription`}
        data-cy={`${type}EventSubscriptionPage`}
      />
      <Grid container direction={"column"} rowSpacing={5}>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h3"
              gutterBottom
              display="inline"
              data-cy={`${type}EventSubscriptionHeader`}
            >
              {`${capitalize(type)} Event Subscription`}
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Typography>Administration</Typography>
              <MuiLink component={NavLink} to="/teams/manage">
                Teams
              </MuiLink>
              <MuiLink component={NavLink} to={`/teams/edit/${teamId}`}>
                {`Edit team${teamName ? ` - ${teamName}` : ""}`}
              </MuiLink>
              <MuiLink
                component={NavLink}
                to={`/teams/edit/${teamId}?defaultTab=event-subscriptions`}
              >
                Check-in notifications
              </MuiLink>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider my={6} />
      </Grid>
      <Grid container item flexGrow={1} alignItems={"stretch"}>
        <Grid item xs={12}>
          <EventSubscriptionForm
            teamId={teamId!}
            eventSubscription={eventSubscription}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default EditEventSubscription;
