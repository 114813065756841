import { ThemeWithVisiblyExtensions } from "../../../theme";
import { ResolutionType } from "../models/activity";

export const getBackgroundColorForAlert = (
  score: number,
  theme: ThemeWithVisiblyExtensions
) => {
  switch (true) {
    case score === 1:
      return theme.visibly.alerts.level1.color;
    case score === 2:
      return theme.visibly.alerts.level2.color;
    case score === 10:
      return theme.visibly.alerts.level10.color;
    default:
      return theme.visibly.alerts.default.color;
  }
};

export const getTextForResolutionType = (
  resolution: ResolutionType,
  activityOwnerName: string
) => {
  switch (true) {
    case resolution === ResolutionType.noneRequired:
      return "None required";
    case resolution === ResolutionType.onSiteContact:
      return `Visited ${activityOwnerName} on site`;
    case resolution === ResolutionType.remoteContact:
      return `Remotely contacted ${activityOwnerName}`;
    case resolution === ResolutionType.selfResolved:
      return `${activityOwnerName} resolved themselves`;
    default:
      return "Unknown";
  }
};

export const getTextForAlertLevel = (score: number) => {
  switch (true) {
    case score === 0:
      return "No alert";
    case score === 1:
      return "Level 1";
    case score === 2:
      return "Level 2";
    case score === 10:
      return "Silent alarm";
    default:
      return "Unknown";
  }
};

export const getTextForChannels = (channels: string[]) => {
  const channelNames = channels.map((channel) => {
    if (channel === "mobileApp" || channel === "pushNotification") {
      return "Mobile app";
    }
    if (channel === "email") {
      return "Email";
    }
    return channel;
  });
  return channelNames.join(", ");
};

export const combineAlertLevelSets = (alertLevels: number[][]) => {
  const totalScores = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  alertLevels?.forEach((a) => {
    totalScores[0] += a[0];
    totalScores[1] += a[1];
    totalScores[2] += a[2];
    totalScores[3] = 0;
    totalScores[4] = 0;
    totalScores[5] = 0;
    totalScores[6] = 0;
    totalScores[7] = 0;
    totalScores[8] = 0;
    totalScores[9] = 0;
    totalScores[10] += a[10];
  });

  return totalScores;
};

export const getActivityDueText = (date: Date) => {
  const dateInMilliSeconds = Date.parse(date.toString());
  const nowInMilliSeconds = Date.parse(new Date().toString());

  const diffInSeconds = Math.round(
    (dateInMilliSeconds - nowInMilliSeconds) / 1000
  );

  const seconds = Math.abs(diffInSeconds);
  const minutes = Math.ceil(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const isOverdue = diffInSeconds < 0;

  let buffer = "";

  if (isOverdue) {
    buffer += "Overdue by ";
  }

  if (hours >= 1) {
    buffer += `${hours} hour`;
    if (hours >= 2) {
      buffer += "s";
    }

    const remainingMinutes = minutes % 60;
    if (remainingMinutes > 0) {
      buffer += ` and ${remainingMinutes} minute`;
      if (remainingMinutes >= 2) {
        buffer += "s";
      }
    }
  } else if (minutes > 1) {
    buffer += `${minutes} minute`;
    if (minutes >= 2) {
      buffer += "s";
    }
  } else {
    buffer += "less than a minute";
  }

  if (!isOverdue) {
    buffer += " remaining";
  }

  return buffer;
};

export const sumAlertLevelsCount = (scores: number[]) => {
  return scores.reduce((sum: number, current: number) => sum + current, 0);
};

export const calcActivitiesAverage = (total: number, count: number) => {
  if (count === 0) {
    return "0";
  }

  const fraction = total / count;
  return fraction.toFixed(0);
};
