import { AxiosPromise } from "axios";
import { AdditionalInfo, User } from "../models/user";
import axios from "../../../utils/axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";

type UserResponse = {
  data: User;
  meta: ApiResponseMeta;
};

async function getUser(userId: string): Promise<AxiosPromise<UserResponse>> {
  return await axios.get(`users/v1/${userId}`);
}

export type CreateUserRequest = {
  forenames: string;
  lastName: string;
  recoveryEmail?: string;
  mobileNumber?: string;
  handle?: string;
  password: string;
  inviteId?: string | null;
  email: string;
  additionalInfo?: AdditionalInfo;
};

export type UpdateUserRequest = {
  id: string;
  forenames: string;
  lastName: string;
  mobileNumber?: string;
  additionalInfo?: AdditionalInfo;
};

async function createUser(
  request: CreateUserRequest
): Promise<AxiosPromise<UserResponse>> {
  return await axios.post(`users/v1`, request);
}

async function updateUser(request: UpdateUserRequest): Promise<AxiosPromise> {
  return await axios.put(`users/v1/${request.id}`, request);
}

export { getUser, createUser, updateUser };
