import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { Assignment } from "../models/assignment";
import {
  QueryAssignmentsRequest,
  queryAssignmentsAsync,
} from "../services/assignmentService";

export const queryAssignments = createAsyncThunk(
  "assignments/queryAssignments",
  async (request: QueryAssignmentsRequest, { rejectWithValue }) => {
    try {
      const queryAssignmentsResponse = await queryAssignmentsAsync(request);

      return queryAssignmentsResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface AssignmentsStat {
  assignments: Assignment[];
  assignment?: Assignment;
  status: LoadingStatus;
  error: string;
}

const initialState: AssignmentsStat = {
  assignments: [],
  assignment: undefined,
  status: LoadingStatus.idle,
  error: "",
};

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryAssignments.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(queryAssignments.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        assignments: action.payload.data,
      };
    });
    builder.addCase(queryAssignments.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        assignments: [],
        error: "Failed to get accreditation role assignments.",
      };
    });
  },
});

export default assignmentsSlice.reducer;
