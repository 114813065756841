import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";

import * as learningCoursesService from "../services/learningCoursesService";
import { CourseSpec } from "../models/courseSpec";
import { BulkEnrol } from "../models/enrolment";

export type QueryCourseSpecsRequest = {
  orgId: string;
  states?: string[];
};

export const queryCourseSpecs = createAsyncThunk(
  "learning-courses/queryCourses",
  async (request: QueryCourseSpecsRequest, { rejectWithValue }) => {
    try {
      return (await learningCoursesService.queryCourseSpecsAsync(request)).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export type BulkEnrolRequest = {
  orgId: string;
  courseSpecId: string;
  userIds: string[];
};

export const bulkEnrol = createAsyncThunk(
  "learning-enrolment/bulkEnrol",
  async (request: BulkEnrolRequest, { rejectWithValue }) => {
    try {
      return (
        await learningCoursesService.bulkEnrolAsync(
          request.courseSpecId,
          request
        )
      ).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface LearningCoursesState {
  courseSpecs: CourseSpec[];
  status: LoadingStatus;
  bulkEnrolResponse?: BulkEnrol;
  error: string;
}

const initialState: LearningCoursesState = {
  courseSpecs: [],
  status: LoadingStatus.idle,
  error: "",
};

const learningCoursesSlice = createSlice({
  name: "learningCourses",
  initialState,
  reducers: {
    clearBulkEnrolResponse: (state) => {
      state.bulkEnrolResponse = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryCourseSpecs.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(queryCourseSpecs.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        courseSpecs: action.payload.data,
      };
    });
    builder.addCase(queryCourseSpecs.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        courses: [],
        error: "Failed to query courses",
      };
    });

    builder.addCase(bulkEnrol.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(bulkEnrol.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        bulkEnrolResponse: action.payload.data,
      };
    });
    builder.addCase(bulkEnrol.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to bulk enrol",
      };
    });
  },
});

export default learningCoursesSlice.reducer;

export const { clearBulkEnrolResponse } = learningCoursesSlice.actions;
