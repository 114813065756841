import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../../models/apiResponseMeta";
import { Invite, InviteData } from "../models/invite";
import axios from "../../../utils/axios";
import { GetInvitesRequest } from "../slices/invitesSlice";

type GetInvitesResponse = {
  data: Invite[];
  meta: ApiResponseMeta;
};

async function getInvites(
  request: GetInvitesRequest
): Promise<AxiosPromise<GetInvitesResponse>> {
  return await axios.post(`invites/v1/query`, request);
}

type ResendInviteRequest = {
  orgId: string;
  inviteId: string;
};

type ResendInviteResponse = {
  meta: ApiResponseMeta;
};

async function resendInvite(
  request: ResendInviteRequest
): Promise<AxiosPromise<ResendInviteResponse>> {
  const body = {
    orgId: request.orgId,
    inviteId: request.inviteId,
  };

  return await axios.post(`invites/v1/resend-invite`, body);
}

type CreateInviteRequest = {
  forenames: string;
  lastName: string;
  inviteEmail: string;
  orgId: string;
  inviteData: InviteData;
};

type CreateInviteResponse = {
  data: Invite;
  meta: ApiResponseMeta;
};

async function createInvite(
  request: CreateInviteRequest
): Promise<AxiosPromise<CreateInviteResponse>> {
  return await axios.post(`invites/v1`, request);
}

export { getInvites, resendInvite, createInvite };
