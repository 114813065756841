import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { queryAccreditationAsync } from "../services/accreditationService";
import { Accreditation, AccreditationState } from "../models/accreditation";

export type QueryAccreditationRequest = {
  orgId: string;
  accreditationSpecIds?: number[];
  teamIds?: string[];
  states?: AccreditationState[];
  userId?: string;
};

export const queryAccreditations = createAsyncThunk(
  "accreditations/query",
  async (request: QueryAccreditationRequest, { rejectWithValue }) => {
    try {
      return (await queryAccreditationAsync(request)).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface AccreditationsState {
  accreditations: Accreditation[];
  status: LoadingStatus;
  error: string;
}

const initialState: AccreditationsState = {
  accreditations: [],
  status: LoadingStatus.idle,
  error: "",
};

const accreditationsSlice = createSlice({
  name: "accreditations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryAccreditations.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(queryAccreditations.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditations: action.payload.data,
      };
    });
    builder.addCase(queryAccreditations.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        accreditations: [],
        error: "Failed to query accreditations",
      };
    });
  },
});

export default accreditationsSlice.reducer;
