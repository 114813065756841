import { ContactInfo } from "../../../models/organisationMember";

export enum ResolutionType {
  noneRequired = "noneRequired",
  selfResolved = "selfResolved",
  remoteContact = "remoteContact",
  onSiteContact = "onSiteContact",
}

export interface Activity {
  id: string;
  createdAt: Date;
  closedAt?: Date;
  dueAt: Date;
  forceCloseAt: Date;
  activityTypeId: string;
  activityTypeVersion: number;
  userId: string;
  userLastname: string;
  userForenames: string;
  name: string;
  description: string;
  latenessTiers: number[];
  alertLevel: number;
  peakAlertLevel: number;
  evaluateAt: Date;
  state: string;
  orgId: string;
  userMobileNumber: string;
  userInOrgEmail: string;
  motionTracking: MotionTracking;
  resolution?: ResolutionType;
  contactInfo: ContactInfo;
}

export interface CheckInUser {
  userId: string;
  userLastname: string;
  userForenames: string;
  activityId?: string;
  closeAt?: Date;
  dueAt: Date;
  forceCloseAt?: Date;
  activityTypeId: string;
  activityTypeVersion: number;
  name: string;
  description: string;
  latenessTiers: number[];
  alertLevel: number;
  evaluateAt: Date;
  state: string;
  orgId: string;
  userMobileNumber: string;
  userInOrgEmail: string;
  activitySession?: ActivitySession;
}

export interface ActivitySession {
  id: string;
  createdAt: Date;
  closedAt: Date;
  state: string;
}

interface MotionTracking {
  enabled: boolean;
  nextPingAt: Date;
  evaluateAt: Date;
  samples: MotionTrackingSample[];
  lastSampleEvaluated: MotionTrackingSample;
  monitorState: string;
}

interface MotionTrackingSample {
  createdAt: Date;
  trigger: string;
  motionState: string;
}
