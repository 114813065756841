import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { OrganisationOverview } from "../models/organisationOverview";
import * as organisationStatsService from "../services/organisationStatsService";

export const getOrganisationOverview = createAsyncThunk(
  "stats-organisation/overview",
  async (orgId: string, { rejectWithValue }) => {
    try {
      return (await organisationStatsService.getOrganisationOverview(orgId))
        .data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface OrganisationStatsState {
  organisationOverview?: OrganisationOverview;
  status: LoadingStatus;
  error: string;
}

const initialState: OrganisationStatsState = {
  status: LoadingStatus.idle,
  error: "",
};

const organisationStatsSlice = createSlice({
  name: "organisationOverview",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganisationOverview.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getOrganisationOverview.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationOverview: action.payload.data,
      };
    });

    builder.addCase(getOrganisationOverview.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get organisation overview",
      };
    });
  },
});

export default organisationStatsSlice.reducer;
