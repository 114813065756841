import styled from "@emotion/styled";
import {
  Autocomplete,
  Button as MuiButton,
  CardContent,
  CircularProgress,
  Grid,
  Card as MuiCard,
  TextField as MuiTextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
} from "@mui/material";
import { Box, SpacingProps, spacing } from "@mui/system";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useAuth from "../../../../hooks/useAuth";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import {
  EventSubscription,
  EventSubscriptionType,
} from "../../models/eventSubscriptions";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import { useEffect } from "react";
import useAppSelector from "../../../../hooks/useAppSelector";
import { LoadingStatus } from "../../../../models/loadingStatus";
import {
  createEventSubscription,
  updateEventSubscription,
} from "../../services/eventSubscriptionsService";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";
import { queryCommunicationGroups } from "../../../communications/slices/communicationGroupsSlice";

const Card = styled(MuiCard)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type EventSubscriptionFormProps = {
  teamId: string;
  eventSubscription?: EventSubscription;
};

function EventSubscriptionForm({
  teamId,
  eventSubscription,
}: EventSubscriptionFormProps) {
  const { currentOrgId } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const communicationGroups = useAppSelector(
    (state) => state.communicationGroups.communicationGroups
  );
  const communicationGroupsStatus = useAppSelector(
    (state) => state.communicationGroups.queryStatus
  );

  useEffect(() => {
    if (currentOrgId) {
      dispatch(
        queryCommunicationGroups({
          orgId: currentOrgId,
        })
      );
    }
  }, [currentOrgId, dispatch]);

  if (communicationGroupsStatus !== LoadingStatus.succeeded) {
    return (
      <Grid container mt={10}>
        <Grid item xs>
          <LoadingStatusIndicator
            loadingStatuses={[communicationGroupsStatus]}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Card mt={4}>
      <Formik
        initialValues={{
          communicationGroup: communicationGroups.find(
            (el) => el.id === eventSubscription?.communicationGroupId
          ),
          alertLevel: eventSubscription?.options?.alertLevel,
        }}
        validationSchema={Yup.object().shape({
          communicationGroup: Yup.object().required("Required"),
          alertLevel: Yup.number().required("Required"),
        })}
        onSubmit={async (values, { setStatus, setSubmitting }) => {
          try {
            if (currentOrgId && teamId && values.communicationGroup) {
              if (eventSubscription?.id) {
                await updateEventSubscription(eventSubscription?.id, {
                  id: eventSubscription?.id,
                  orgId: currentOrgId,
                  teamId: teamId,
                  event: EventSubscriptionType.CheckInAlert,
                  options: {
                    event: EventSubscriptionType.CheckInAlert,
                    alertLevel: values.alertLevel,
                  },
                  communicationGroupId: values.communicationGroup.id,
                }).then(() => {
                  const snackbarKey = enqueueSnackbar(
                    `Event subscription updated!`,
                    {
                      variant: "success",
                      onClick: () => closeSnackbar(snackbarKey),
                    }
                  );
                });
              } else {
                await createEventSubscription({
                  orgId: currentOrgId,
                  teamId: teamId,
                  event: EventSubscriptionType.CheckInAlert,
                  options: {
                    event: EventSubscriptionType.CheckInAlert,
                    alertLevel: values.alertLevel,
                  },
                  communicationGroupId: values.communicationGroup?.id,
                }).then(() => {
                  const snackbarKey = enqueueSnackbar(
                    `Event subscription created!`,
                    {
                      variant: "success",
                      onClick: () => closeSnackbar(snackbarKey),
                    }
                  );
                });
              }

              return navigate(
                queryString.stringifyUrl({
                  url: `/teams/edit/${teamId}`,
                  query: {
                    defaultTab: "event-subscriptions",
                  },
                })
              );
            }
          } catch (error: any) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setSubmitting(false);
            const snackbarKey = enqueueSnackbar(message, {
              variant: "error",
              onClick: () => closeSnackbar(snackbarKey),
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <Card mb={6}>
            <CardContent>
              {isSubmitting ? (
                <Box display="flex" justifyContent="center" m={10}>
                  <CircularProgress />
                </Box>
              ) : (
                <Form>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id="communicationGroup"
                        options={communicationGroups!}
                        getOptionLabel={(option) => {
                          return option.name;
                        }}
                        value={values.communicationGroup}
                        onChange={(e, value) => {
                          console.log(value);
                          setFieldValue("communicationGroup", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="communicationGroup"
                            label="Communication Group"
                            variant="outlined"
                            error={Boolean(
                              touched.communicationGroup &&
                                errors.communicationGroup
                            )}
                            fullWidth
                            data-cy="communicationGroupDropDown"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <FormControl mt={4} style={{ width: "100%" }}>
                        <InputLabel id="alert-level-select-label">
                          Alert Level
                        </InputLabel>
                        <Select
                          labelId="alert-level-select-label"
                          name="alertLevel"
                          value={values.alertLevel}
                          onChange={handleChange}
                          error={Boolean(
                            touched.alertLevel && errors.alertLevel
                          )}
                          onBlur={handleBlur}
                          label="Alert Level"
                          data-cy="alertLevelDropDown"
                        >
                          <MenuItem
                            value={1}
                            data-cy="alertLevelDropDown_level1"
                          >
                            Level 1
                          </MenuItem>
                          <MenuItem
                            value={2}
                            data-cy="alertLevelDropDown_level2"
                          >
                            Level 2
                          </MenuItem>
                          <MenuItem
                            value={10}
                            data-cy="alertLevelDropDown_levelSilentAlarm"
                          >
                            Silent alarm
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    mt={3}
                    data-cy="saveChangesButton"
                  >
                    Save changes
                  </Button>
                </Form>
              )}
            </CardContent>
          </Card>
        )}
      </Formik>
    </Card>
  );
}

export default EventSubscriptionForm;
