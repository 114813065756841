import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  QueryTaskSchedulesRequest,
  TaskSchedule,
} from "../models/taskSchedule";
import * as taskScheduleService from "../services/taskScheduleService";
import { LoadingStatus } from "../../../models/loadingStatus";

export const queryTaskSchedules = createAsyncThunk(
  "taskSchedules/queryTaskSchedules",
  async (request: QueryTaskSchedulesRequest, { rejectWithValue }) => {
    try {
      return (await taskScheduleService.getTaskSchedules(request)).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface TaskScheduleState {
  taskSchedules: TaskSchedule[];
  taskSchedule?: TaskSchedule | undefined;
  status: LoadingStatus;
  error: string;
}

const initialState: TaskScheduleState = {
  taskSchedules: [],
  taskSchedule: undefined,
  status: LoadingStatus.idle,
  error: "",
};

const taskSchedulesSlice = createSlice({
  name: "taskSchedules",
  initialState,
  reducers: {
    setTaskSchedule: (state, action) => {
      return {
        ...state,
        taskSchedule: action.payload,
      };
    },
    clearTaskSchedule: (state) => {
      return {
        ...state,
        taskSchedule: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryTaskSchedules.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(queryTaskSchedules.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSchedules: action.payload.data,
      };
    });

    builder.addCase(queryTaskSchedules.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        taskSchedules: [],
      };
    });
  },
});

export const { setTaskSchedule, clearTaskSchedule } =
  taskSchedulesSlice.actions;

export default taskSchedulesSlice.reducer;
