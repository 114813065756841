import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { OrganisationMember } from "../../../models/organisationMember";
import {
  getOrganisationMemberDetailsAsync,
  GetOrganisationMemberDetailsRequest,
} from "../services/organisationMemberService";

interface OrganisationMemberState {
  organisationMemberDetails: OrganisationMember;
  status: LoadingStatus;
  error: string;
}

const initialState: OrganisationMemberState = {
  organisationMemberDetails: {} as OrganisationMember,
  status: LoadingStatus.idle,
  error: "",
};

export const getOrganisationMemberDetails = createAsyncThunk(
  "userOrganisationMember/getOrganisationMemberDetails",
  async (request: GetOrganisationMemberDetailsRequest, { rejectWithValue }) => {
    try {
      const response = await getOrganisationMemberDetailsAsync(request);

      return response.data;
    } catch (err: any) {
      console.warn(err);
      return rejectWithValue({
        error: err.errorMessage,
      });
    }
  }
);

const userOrganisationMembersSlice = createSlice({
  name: "userOrganisationMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganisationMemberDetails.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
        organisationMemberDetails: {} as OrganisationMember,
      };
    });
    builder.addCase(getOrganisationMemberDetails.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationMemberDetails: action.payload.data,
      };
    });
    builder.addCase(getOrganisationMemberDetails.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        organisationMemberDetails: {} as OrganisationMember,
      };
    });
  },
});

export default userOrganisationMembersSlice.reducer;
