import React from "react";
import { useCurrentOrg } from "../../../user/hooks/userHooks";

interface CanViewChallengeSetPerformanceNavItemGuardProps {
  children: JSX.Element;
}

function CanViewChallengeSetPerformanceNavItemGuard({
  children,
}: CanViewChallengeSetPerformanceNavItemGuardProps) {
  const currentOrg = useCurrentOrg();

  if (!currentOrg?.featureFlags?.canViewChallengeSetPerformance) {
    return <></>;
  }

  return <>{children}</>;
}

export default CanViewChallengeSetPerformanceNavItemGuard;
