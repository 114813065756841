import {
  CourseSpecAchievements,
  CourseSpecAdditionalInfo,
  CourseSpecStructure,
} from "./courseSpec";

export interface Enrolment {
  id: string;
  createdAt: Date;
  courseSpecId: string;
  orgId: string;
  state: EnrolmentState;
  name: string;
  additionalInfo: CourseSpecAdditionalInfo;
  structure: CourseSpecStructure;
  achievements: CourseSpecAchievements;
  progressPercentage: number;
  scorePercentage: number;
  assessment: CourseEnrolmentAssessment;
  userId: string;
  userForenames: string;
  userLastName: string;
  userEmail: string;
  completedAt: Date;
}

interface CourseEnrolmentAssessment {
  requestedAt: Date;
}

export enum EnrolmentState {
  open = "open",
  complete = "complete",
}

export interface BulkEnrol {
  numberOfUserEnrolled: number;
  numberOfUsersAlreadyEnrolled: number;
  numberOfUsersNotAbleToEnrol: number;
}
