import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { UserSummary as UserStatsSummary } from "../models/userSummary";
import { getUserSummary } from "../services/userStatsService";

type SummaryProps = {
  orgId: string;
  userId: string;
  timeRange: string;
};

export const getUserStatsSummary = createAsyncThunk(
  "userStats/summary",
  async (request: SummaryProps, { rejectWithValue }) => {
    try {
      const response = await getUserSummary(
        request.userId,
        request.orgId,
        request.timeRange
      );

      return response.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface UserStatsState {
  userStatsSummary: UserStatsSummary;
  status: LoadingStatus;
  error: string;
}

const initialState: UserStatsState = {
  userStatsSummary: {} as UserStatsSummary,
  status: LoadingStatus.idle,
  error: "" as string,
};

const userStatsSlice = createSlice({
  name: "userSummary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserStatsSummary.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getUserStatsSummary.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        userStatsSummary: action.payload.data,
      };
    });
    builder.addCase(getUserStatsSummary.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        userStatsSummary: {} as UserStatsSummary,
      };
    });
  },
});

export default userStatsSlice.reducer;
