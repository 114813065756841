import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { RootState } from "../../../redux/store";
import { User } from "../models/user";
import * as userService from "../services/userService";
import { Location } from "react-router-dom";
import { CreateUserRequest, UpdateUserRequest } from "../services/userService";

interface UserState {
  user?: User;
  includeExpiredChallengesInScores: boolean;
  showIncludeExpiredChallengesToggle: boolean;
  appEntryLocation: Location | null;
  status: LoadingStatus;
  error: string;
}

const initialIncludeExpiredChallengesInScores =
  "1" === localStorage.getItem("includeExpiredChallengesInScores");

const initialState: UserState = {
  user: undefined,
  includeExpiredChallengesInScores: initialIncludeExpiredChallengesInScores,
  showIncludeExpiredChallengesToggle: false,
  appEntryLocation: null,
  status: LoadingStatus.idle,
  error: "",
};

export const getUser = createAsyncThunk(
  "user/getUser",
  async (userId: string, { rejectWithValue }) => {
    try {
      return (await userService.getUser(userId)).data;
    } catch (err: any) {
      console.warn(err);
      return rejectWithValue({
        error: err.errorMessage,
      });
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (request: CreateUserRequest, { rejectWithValue }) => {
    try {
      return (await userService.createUser(request)).data;
    } catch (err: any) {
      console.warn(err);
      return rejectWithValue({
        error: err,
      });
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (request: UpdateUserRequest, { rejectWithValue }) => {
    try {
      await userService.updateUser(request);
    } catch (err: any) {
      console.warn(err);
      return rejectWithValue({
        error: err.errorMessage,
      });
    }
  }
);

export const selectUser = (state: RootState) => {
  return state.user?.user;
};

export const selectIncludeExpiredChallengesInScores = (state: RootState) => {
  return !!state.user?.includeExpiredChallengesInScores;
};

export const setIncludeExpiredChallengesInScores = createAction(
  "user/setIncludeExpiredChallengesInScores",
  (include: boolean) => {
    localStorage.setItem(
      "includeExpiredChallengesInScores",
      include ? "1" : "0"
    );

    return {
      payload: {
        include,
      },
    };
  }
);

export const setAppEntryLocation = createAction(
  "user/setAppEntryLocation",
  (location: Location) => {
    return {
      payload: {
        location,
      },
    };
  }
);

export const selectAppEntryLocation = (state: RootState) => {
  return state.user?.appEntryLocation;
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showIncludeExpiredChallengesToggle: (state) => {
      return {
        ...state,
        showIncludeExpiredChallengesToggle: true,
      };
    },
    hideIncludeExpiredChallengesToggle: (state) => {
      return {
        ...state,
        showIncludeExpiredChallengesToggle: false,
      };
    },
    clearAppEntryLocation: (state) => {
      return {
        ...state,
        appEntryLocation: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        user: action.payload.data,
      };
    });
    builder.addCase(getUser.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });

    builder.addCase(updateUser.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });

    builder.addCase(createUser.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        user: action.payload.data,
      };
    });
    builder.addCase(createUser.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });

    builder.addCase(setIncludeExpiredChallengesInScores, (state, action) => {
      return {
        ...state,
        includeExpiredChallengesInScores: action.payload.include,
      };
    });
    builder.addCase(setAppEntryLocation, (state, action) => {
      if (state.appEntryLocation != null) {
        return state;
      }

      return {
        ...state,
        appEntryLocation: action.payload.location,
      };
    });
  },
});

export const {
  showIncludeExpiredChallengesToggle,
  hideIncludeExpiredChallengesToggle,
  clearAppEntryLocation,
} = userSlice.actions;

export default userSlice.reducer;
