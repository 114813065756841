import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  TaskSpecChallengeActivitiesRequest,
  TaskSpecChallengeActivity,
} from "../models/taskStats";
import { taskSpecChallengeActivitiesQuery } from "../services/taskStatsService";

export const getTaskSpecChallengeActivities = createAsyncThunk(
  "taskStats/challengeActivityByTaskSpec",
  async (request: TaskSpecChallengeActivitiesRequest, { rejectWithValue }) => {
    try {
      const result = await taskSpecChallengeActivitiesQuery(request);

      return result.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface TaskStatsState {
  taskSpecChallengeActivities: TaskSpecChallengeActivity[];
  status: LoadingStatus;
  error: string;
}

const initialState: TaskStatsState = {
  taskSpecChallengeActivities: {} as TaskSpecChallengeActivity[],
  status: LoadingStatus.idle,
  error: "",
};

const tasksStatsSlice = createSlice({
  name: "taskStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaskSpecChallengeActivities.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(
      getTaskSpecChallengeActivities.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          taskSpecChallengeActivities: action.payload.data,
        };
      }
    );
    builder.addCase(
      getTaskSpecChallengeActivities.rejected,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.failed,
          taskSpecChallengeActivities: {} as TaskSpecChallengeActivity[],
        };
      }
    );
  },
});

export default tasksStatsSlice.reducer;
