import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { ChallengeShort } from "../models/challengeShort";
import * as challengeService from "../services/challengeService";

export type GetOutstandingChallengesRequest = {
  orgId: string;
  userId?: string;
  filter?: string;
  type: "submissions" | "reviews";
};

export const getOutstandingChallenges = createAsyncThunk(
  "challengeInstances/getOutstandingChallenges",
  async (request: GetOutstandingChallengesRequest, { rejectWithValue }) => {
    try {
      switch (request.type) {
        case "reviews":
          return (await challengeService.getOutstandingReviews(request)).data;

        case "submissions":
          return (await challengeService.getOutstandingSubmissions(request))
            .data;
      }
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export type GetChallengesRequest = {
  orgId: string;
  states?: string[];
  challengeCreatedAtTimeRange?: string;
  reviewCreatedAtTimeRange?: string;
  taskSpecId?: string;
  taskSpecMixedContentId?: string;
  challengerUserId?: string;
  reviewerUserId?: string;
};

export const getChallenges = createAsyncThunk(
  "challengeInstances/query",
  async (props: GetChallengesRequest, { rejectWithValue }) => {
    try {
      return (await challengeService.getChallenges(props)).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface ChallengeState {
  challenges: ChallengeShort[];
  status: LoadingStatus;
  error: string;
}

const initialState: ChallengeState = {
  challenges: [],
  status: LoadingStatus.idle,
  error: "",
};

const challengesSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOutstandingChallenges.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getOutstandingChallenges.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challenges: action.payload.data,
      };
    });

    builder.addCase(getOutstandingChallenges.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get challengeInstances",
      };
    });
    builder.addCase(getChallenges.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getChallenges.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challenges: action.payload.data,
      };
    });

    builder.addCase(getChallenges.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get challengeInstances",
      };
    });
  },
});

export default challengesSlice.reducer;
