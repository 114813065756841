import {
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  CardContent,
  Grid,
  CircularProgress,
  FormControl,
  FormLabel,
  Switch,
  FormHelperText,
  Divider,
} from "@mui/material";
import { Formik, Form } from "formik";
import { Box, spacing, SpacingProps } from "@mui/system";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { TeamCapabilities, Team } from "../../models/teams";
import { useSnackbar } from "notistack";
import { capabilitiesSwitchStyle } from "../../utils/capabilitiesUtils";
import { createTeam, updateTeam } from "../../services/teamsService";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { clearCurrentTeam, getTeam } from "../../slices/teamsSlice";
import { useCurrentOrg } from "../../../user/hooks/userHooks";
import { clearCurrentTeamMembers } from "../../slices/teamMembersSlice";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type EditTeamFormProps = {
  team?: Team;
  type: "create" | "update";
};

function EditTeamForm({ team, type }: EditTeamFormProps) {
  const dispatch = useAppDispatch();
  const currentOrg = useCurrentOrg();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const getCapabilitiesList = (
    skillsCapability: boolean,
    checkInsCapability: boolean
  ): TeamCapabilities[] => {
    var result = [];

    if (skillsCapability) {
      result.push(TeamCapabilities.Skills);
    }

    if (checkInsCapability) {
      result.push(TeamCapabilities.CheckIns);
    }

    return result;
  };

  return (
    <Formik
      initialValues={{
        teamName: team?.name,
        teamDescription: team?.description,
        skillsCapability:
          team?.capabilities?.find((e) => e === TeamCapabilities.Skills) !==
          undefined,
        checkInsCapability:
          team?.capabilities?.find((e) => e === TeamCapabilities.CheckIns) !==
          undefined,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        teamName: Yup.string().required("Required"),
        teamDescription: Yup.string().required("Required"),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        try {
          if (type === "update") {
            await updateTeam({
              id: team!.id,
              name: values.teamName!,
              description: values.teamDescription,
              capabilities: getCapabilitiesList(
                values.skillsCapability,
                values.checkInsCapability
              ),
            });
            const snackbarKey = enqueueSnackbar(
              `Team ${values.teamName} ${type}d!`,
              {
                variant: "success",
                onClick: () => closeSnackbar(snackbarKey),
              }
            );
            dispatch(getTeam(team!.id));
          } else {
            await createTeam({
              orgId: currentOrg!.id,
              name: values.teamName!,
              description: values.teamDescription,
              capabilities: getCapabilitiesList(
                values.skillsCapability,
                values.checkInsCapability
              ),
            }).then((response: any) => {
              dispatch(clearCurrentTeam());
              dispatch(clearCurrentTeamMembers());
              return navigate(
                queryString.stringifyUrl({
                  url: `/teams/edit/${response.data.data.id}`,
                  query: {
                    defaultTab: "team-members",
                  },
                })
              );
            });

            const snackbarKey = enqueueSnackbar(
              `Team ${values.teamName} created!`,
              {
                variant: "success",
                onClick: () => closeSnackbar(snackbarKey),
              }
            );
          }
        } catch (error: any) {
          const apiErrorMessage = error.response.data?.meta?.errorMessage;

          let message = "Something went wrong";
          if (apiErrorMessage) {
            if (apiErrorMessage.includes("duplicate key")) {
              message = `The team name "${values.teamName}" is already in use`;
            } else {
              message = apiErrorMessage;
            }
          }

          setStatus({ success: false });
          setSubmitting(false);
          const snackbarKey = enqueueSnackbar(message, {
            variant: "error",
            onClick: () => closeSnackbar(snackbarKey),
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card mb={6}>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" m={10}>
                <CircularProgress />
              </Box>
            ) : (
              <Form>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name="teamName"
                          label="Team Name"
                          value={values.teamName}
                          error={Boolean(touched.teamName && errors.teamName)}
                          fullWidth
                          helperText={touched.teamName && errors.teamName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          data-cy="teamNameTextField"
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name="teamDescription"
                          label="Team Description"
                          value={values.teamDescription}
                          error={Boolean(
                            touched.teamDescription && errors.teamDescription
                          )}
                          fullWidth
                          helperText={
                            touched.teamDescription && errors.teamDescription
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          data-cy="teamDescriptionTextField"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} mt={1} pt={0}>
                    {currentOrg?.capabilities?.find(
                      (e) => e === TeamCapabilities.Skills
                    ) && (
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <FormControl>
                            <FormLabel>
                              Enable skill challenges for this team
                            </FormLabel>
                            <Switch
                              sx={capabilitiesSwitchStyle}
                              color="primary"
                              checked={values.skillsCapability ?? false}
                              onChange={handleChange}
                              name="skillsCapability"
                              data-cy="enableSkillsChallengesSwitch"
                            />
                          </FormControl>
                          <FormHelperText>
                            When enabled, members of this team will be available
                            to receive skill challenges.
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} mb={4}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                    {currentOrg?.capabilities?.find(
                      (e) => e === TeamCapabilities.CheckIns
                    ) && (
                      <Grid container spacing={6}>
                        <Grid item xs={12}>
                          <FormControl>
                            <FormLabel>
                              Enable Check-ins for this team
                            </FormLabel>
                            <Switch
                              sx={capabilitiesSwitchStyle}
                              color="primary"
                              checked={values.checkInsCapability ?? false}
                              onChange={handleChange}
                              name="checkInsCapability"
                              data-cy="enableCheckInsChallengesSwitch"
                            />
                          </FormControl>
                          <FormHelperText>
                            When enabled, members of this team will be able to
                            use the Check-in feature to track their current
                            activity and raise alarms.
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  mt={3}
                  style={{ float: "right" }}
                  data-cy="saveChangesButton"
                >
                  Save changes
                </Button>
              </Form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default EditTeamForm;
