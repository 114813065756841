export interface AdditionalInfo {
  acceptedEulaVersion?: number;
}

export interface User {
  id: string;
  username: string;
  email: string;
  mobileNumber: string;
  forenames: string;
  lastName: string;
  verified: boolean;
  createdAt: string;
  orgs: UserOrganisation[];
  groups?: VisiblyGroups[];
  handle: string;
  recoveryEmail?: string;
  additionalInfo: AdditionalInfo;
}

export enum VisiblyGroups {
  "visibly-support-group" = "visibly-support-group",
  "visibly-admin-group" = "visibly-admin-group",
  "visibly-learning-practical-reviewer-group" = "visibly-learning-practical-reviewer-group",
  "visibly-external-partner-group" = "visibly-external-partner-group",
  "visibly-learning-designer-group" = "visibly-learning-designer-group",
}

interface OrganisationOptions {
  reviewHierarchies: ReviewHierarchyOptions[];
}

interface ReviewHierarchyOptions {
  tier: number;
  percentage: number;
}

export enum UserOrganisationRole {
  "owner" = "owner",
  "admin" = "admin",
  "member" = "member",
}

export interface UserOrganisation {
  id: string;
  name: string;
  role: UserOrganisationRole;
  featureFlags: FeatureFlags;
  options: OrganisationOptions;
  capabilities: string[];
}

export interface FeatureFlags {
  canUseChallengeLibrary: boolean;
  canMakeTemplatePublic: boolean;
  canUseHierarchicalReviews: boolean;
  canUseDeviceMediaForChallenges: boolean;
  canViewChallengeSetPerformance: boolean;
  canMakePublicAccreditationSpecs: boolean;
}
