import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  QueryCommunicationGroupsRequest,
  getCommunicationGroupAsync,
  queryCommunicationGroupsAsync,
} from "../services/communicationGroupsService";
import {
  CommunicationGroup,
  CommunicationGroupWithCommunications,
} from "../models/communicationGroups";

export const queryCommunicationGroups = createAsyncThunk(
  "communications/queryCommunicationGroups",
  async (request: QueryCommunicationGroupsRequest, { rejectWithValue }) => {
    try {
      return (await queryCommunicationGroupsAsync(request)).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const getCommunicationGroup = createAsyncThunk(
  "communications/getCommunicationGroup",
  async (id: string, { rejectWithValue }) => {
    try {
      return (await getCommunicationGroupAsync(id)).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface CommunicationGroupsState {
  communicationGroups: CommunicationGroup[];
  communicationGroup?: CommunicationGroupWithCommunications;
  queryStatus: LoadingStatus;
  getStatus: LoadingStatus;
  error: string;
}

const initialState: CommunicationGroupsState = {
  communicationGroups: [],
  queryStatus: LoadingStatus.idle,
  getStatus: LoadingStatus.idle,
  error: "",
};

const communicationGroupsSlice = createSlice({
  name: "communicationGroups",
  initialState,
  reducers: {
    resetCommunicationGroupState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryCommunicationGroups.pending, (state) => {
      return {
        ...state,
        queryStatus: LoadingStatus.loading,
      };
    });
    builder.addCase(queryCommunicationGroups.fulfilled, (state, action) => {
      return {
        ...state,
        queryStatus: LoadingStatus.succeeded,
        communicationGroups: action.payload.data,
      };
    });
    builder.addCase(queryCommunicationGroups.rejected, (state, action) => {
      return {
        ...state,
        queryStatus: LoadingStatus.failed,
      };
    });
    builder.addCase(getCommunicationGroup.pending, (state) => {
      return {
        ...state,
        getStatus: LoadingStatus.loading,
      };
    });
    builder.addCase(getCommunicationGroup.fulfilled, (state, action) => {
      return {
        ...state,
        getStatus: LoadingStatus.succeeded,
        communicationGroup: action.payload.data,
      };
    });
    builder.addCase(getCommunicationGroup.rejected, (state, action) => {
      return {
        ...state,
        getStatus: LoadingStatus.failed,
      };
    });
  },
});

export default communicationGroupsSlice.reducer;
export const { resetCommunicationGroupState } =
  communicationGroupsSlice.actions;
