import { getOrganisationMembers } from "../../../users/slices/usersOrganisationMembersSlice";
import { Divider, Grid, Paper, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import { getCurrentOrgId } from "../../../../contexts/AmplifyContext";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/Modal";
import { useSnackbar } from "notistack";
import { LoadingStatus } from "../../../../models/loadingStatus";
import AgGridTable from "../../../../components/AgGridTable";
import {
  ColDef,
  FirstDataRenderedEvent,
  ICellRendererParams,
  RowSelectedEvent,
} from "ag-grid-community";
import { OrganisationMember } from "../../../../models/organisationMember";
import { addTeamMembers, removeTeamMembers } from "../../services/teamsService";
import { getTeamMembers } from "../../slices/teamMembersSlice";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";

type TeamMembersModalProps = {
  initialSelectedUserIds: string[];
  teamId: string;
  show: boolean;
  onClose: () => void;
};

function TeamMembersModal({
  initialSelectedUserIds,
  teamId,
  show,
  onClose,
}: TeamMembersModalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentOrgId = getCurrentOrgId();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);

  const organisationMembers = useAppSelector(
    (state: RootState) => state.usersOrganisationMembers.organisationMembers
  );
  const organisationMembersStatus = useAppSelector(
    (state: RootState) => state.usersOrganisationMembers.status
  );
  const teamMembersStatus = useAppSelector(
    (state: RootState) => state.teamMembers.status
  );

  useEffect(() => {
    setSelectedUserIds(initialSelectedUserIds);
  }, [initialSelectedUserIds]);

  useEffect(() => {
    dispatch(
      getOrganisationMembers({
        orgId: currentOrgId!,
        active: true,
      })
    );
  }, [dispatch, currentOrgId]);

  const columns: ColDef[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      flex: 0.25,
    },
    {
      field: "forenames",
      headerName: "Name",
      sortable: true,
      flex: 1,
      cellRenderer: (
        props: ICellRendererParams<OrganisationMember, string>
      ) => {
        return (
          <p
            className="visiblyLink"
            onClick={() => {
              navigate(`/users/${props.data?.userId}`);
            }}
            data-cy={`${props.data?.forenames}${props.data?.lastname}TeamMember`}
          >
            {`${props.data?.forenames} ${props.data?.lastname}`}
          </p>
        );
      },
    },
    {
      field: "jobTitle",
      headerName: "Job title",
      sortable: true,
      flex: 1,
      cellRenderer: (props: ICellRendererParams<string>) => {
        return <p data-cy={`${props.value}JobTitle`}>{props.value}</p>;
      },
    },
    {
      field: "inOrgEmail",
      headerName: "Invite email",
      sortable: true,
      flex: 1.5,
      cellRenderer: (props: ICellRendererParams<string>) => {
        return <p data-cy={`${props.value}InOrgEmail`}>{props.value}</p>;
      },
    },
  ];

  const onSaveHandler = async () => {
    setIsUpdating(true);
    const userIdsToRemove = initialSelectedUserIds.filter(
      (userId) => selectedUserIds.indexOf(userId) === -1
    );

    if (userIdsToRemove.length) {
      await removeTeamMembers({
        teamId: teamId,
        userIds: userIdsToRemove,
      });
    }

    const userIdsToAdd = selectedUserIds.filter(
      (userId) => initialSelectedUserIds.indexOf(userId) === -1
    );
    if (userIdsToAdd.length) {
      await addTeamMembers({
        teamId: teamId,
        userIds: userIdsToAdd,
      });
    }

    onClose();

    await dispatch(
      getTeamMembers({
        orgId: currentOrgId!,
        teamId: teamId,
      })
    );

    setIsUpdating(false);

    const snackbarKey = enqueueSnackbar(`Team members list updated!`, {
      variant: "success",
      onClick: () => closeSnackbar(snackbarKey),
    });
  };

  return (
    <>
      <Modal
        showModal={show}
        headerText={"Select team members"}
        onClose={onClose}
        style={{
          maxWidth: 1000,
          top: "10%",
        }}
        buttons={[
          {
            text: "Cancel",
            onClick: onClose,
          },
          {
            text: "Save",
            onClick: onSaveHandler,
            isLoadingButton: true,
            loading: isUpdating,
          },
        ]}
      >
        {organisationMembersStatus !== LoadingStatus.succeeded ||
        teamMembersStatus !== LoadingStatus.succeeded ? (
          <Grid container mt={10}>
            <Grid item xs>
              <LoadingStatusIndicator
                loadingStatuses={[organisationMembersStatus, teamMembersStatus]}
              />
            </Grid>
          </Grid>
        ) : (
          <Paper>
            <Box height={"60vh"} width={"100%"}>
              <AgGridTable
                dataCyTag="teamMembersModalTable"
                data={organisationMembers}
                columns={columns}
                gridOptions={{
                  rowSelection: "multiple",
                  onFirstDataRendered: (
                    params: FirstDataRenderedEvent<OrganisationMember>
                  ) => {
                    params.api.forEachNode((node) =>
                      node.setSelected(
                        !!node.data &&
                          selectedUserIds.includes(node.data.userId)
                      )
                    );
                  },
                  onRowSelected: (
                    event: RowSelectedEvent<OrganisationMember, any>
                  ) => {
                    setSelectedUserIds(
                      event.api
                        .getSelectedRows()
                        .map((element) => element.userId)
                    );
                  },
                }}
                pagination={false}
                search={{ enabled: true }}
                toolbarElements={[
                  <>
                    {selectedUserIds!.length > 0 ? (
                      <Typography variant="subtitle2" component="h6" m={2}>
                        {selectedUserIds!.length} MEMBERS SELECTED
                      </Typography>
                    ) : null}
                  </>,
                ]}
              />
            </Box>
          </Paper>
        )}
        <Divider />
      </Modal>
    </>
  );
}

export default TeamMembersModal;
