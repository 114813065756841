import { Grid, Typography } from "@mui/material";
import { LoadingStatus } from "../models/loadingStatus";
import Loader from "./Loader";
import { Warning } from "@mui/icons-material";

type LoadingStatusIndicatorProps = {
  loadingStatuses?: LoadingStatus[];
  errorMessage?: string;
};

function LoadingStatusIndicator({
  loadingStatuses = [],
  errorMessage,
}: LoadingStatusIndicatorProps) {
  const isLoading =
    loadingStatuses.includes(LoadingStatus.loading) ||
    loadingStatuses.includes(LoadingStatus.idle);

  const hasError = loadingStatuses.includes(LoadingStatus.failed);

  if (isLoading) {
    return <Loader />;
  } else if (hasError) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Warning color="error" fontSize="large" />
          <Typography variant="body2" color="error" textAlign="center">
            {errorMessage || "An error has occurred."}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
}

export default LoadingStatusIndicator;
