import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { Review } from "../models/challengeShort";
import { queryReviews } from "../services/reviewsService";

export type GetReviewsRequest = {
  challengeId: string;
  states: string[];
  reviewerTeamId?: string | null;
  maxReviews?: number | null;
};

export const getReviews = createAsyncThunk(
  "challenge-details/reviews",
  async (request: GetReviewsRequest, { rejectWithValue }) => {
    try {
      const queryReviewsResponse = await queryReviews(request);

      return queryReviewsResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({
        error: err,
      });
    }
  }
);

interface ReviewsState {
  reviews: Review[];
  status: LoadingStatus;
  error: string;
}

const initialState: ReviewsState = {
  reviews: [],
  status: LoadingStatus.idle,
  error: "",
};

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviews.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getReviews.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        reviews: action.payload.data,
      };
    });

    builder.addCase(getReviews.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get challenge reviews.",
      };
    });
  },
});

export default reviewsSlice.reducer;
