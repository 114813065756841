export enum TaskType {
  photo = "photo",
  video = "video",
  mixed = "mixed",
  multiChoiceQuestion = "multiChoiceQuestion",
}

export enum TaskVisibility {
  public = "public",
  org = "org",
}

export enum ReviewType {
  single = "single",
  multi = "multi",
  hierarchical = "hierarchical",
  auto = "auto",
}

export enum TaskSpecCadence {
  daily = "daily",
  weekly = "weekly",
}
