import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  AnyAction,
  createAction,
} from "@reduxjs/toolkit";
import teamsReducer from "../features/teams/slices/teamsSlice";
import userReducer from "../features/user/slices/userSlice";
import counterReducer from "./slices/counter";
import challengeStatsReducer from "../features/skills/slices/challengeStatsSlice";
import publicInviteReducer from "../features/account/slices/publicInviteSlice";
import inviteReducer from "../features/users/slices/invitesSlice";
import organisationStatsReducer from "../features/skills/slices/organisationStatsSlice";
import userOrganisationMemberReducer from "../features/user/slices/userOrganisationMemberSlice";
import usersOrganisationMembersReducer from "../features/users/slices/usersOrganisationMembersSlice";
import userStatsReducer from "../features/user/slices/userStatsSlice";
import taskStatsReducer from "../features/user/slices/taskStatsSlice";
import organisationReducer from "../features/skills/slices/organisationSlice";
import taskSpecReducer from "../features/skills/slices/taskSpecSlice";
import challengeLibraryReducer from "../features/skills/slices/challengeLibrarySlice";
import challengeDetailsReducer from "../features/skills/slices/challengeDetailsSlice";
import reviewsReducer from "../features/skills/slices/reviewsSlice";
import industriesReducer from "../features/organisation/slices/industriesSlice";
import teamMembersReducer from "../features/teams/slices/teamMembersSlice";
import taskScheduleReducer from "../features/skills/slices/taskScheduleSlice";
import reviewDiscrepanciesReducer from "../features/skills/slices/reviewDiscrepanciesSlice";
import challengesWithReviewDiscrepanciesReducer from "../features/skills/slices/challengesWithReviewDiscrepanciesSlice";
import challengeReducer from "../features/skills/slices/challengesSlice";
import invitesCsvReducer from "../features/users/slices/invitesCsvSlice";
import activityTypesCsvReducer from "../features/users/slices/activityTypesCsvSlice";
import tasksCsvReducer from "../features/skills/slices/tasksCsvSlice";
import activitiesReducer from "../features/checkIns/slices/activitySlice";
import auditLogsReducer from "../features/checkIns/slices/auditLogSlice";
import checkInStatsReducer from "../features/checkIns/slices/checkInStatsSlice";
import eventSubscriptionsReducer from "../features/teams/slices/eventSubscriptionsSlice";
import environmentReducer from "../features/account/slices/environmentSlice";
import learningStatsReducer from "../features/learning/slices/learningStatsSlice";
import learningPracticalChallengesReducer from "../features/learning/slices/learningPracticalChallengesSlice";
import learningEnrolmentReducer from "../features/learning/slices/learningEnrolmentSlice";
import learningCoursesReducer from "../features/learning/slices/learningCoursesSlice";
import accreditationRolesReducer from "../features/accreditations/slices/accreditationRolesSlice";
import accreditationSpecsReducer from "../features/accreditations/slices/accreditationSpecSlice";
import accreditationsReducer from "../features/accreditations/slices/accreditationSlice";
import assignmentsReducer from "../features/user/slices/assignmentSlice";
import compliancesReducer from "../features/accreditations/slices/compliancesSlice";
import communicationGroupsReducer from "../features/communications/slices/communicationGroupsSlice";
import accreditationRoleConditionsReducer from "../features/accreditations/slices/accreditationRoleConditionsSlice";

const allReducers = combineReducers({
  challengeLibrary: challengeLibraryReducer,
  challengeStats: challengeStatsReducer,
  counter: counterReducer,
  invites: inviteReducer,
  organisationStats: organisationStatsReducer,
  userOrganisationMember: userOrganisationMemberReducer,
  usersOrganisationMembers: usersOrganisationMembersReducer,
  userStats: userStatsReducer,
  taskStats: taskStatsReducer,
  organisation: organisationReducer,
  publicInvite: publicInviteReducer,
  taskSpec: taskSpecReducer,
  teams: teamsReducer,
  user: userReducer,
  taskSchedule: taskScheduleReducer,
  industries: industriesReducer,
  challengeDetails: challengeDetailsReducer,
  reviews: reviewsReducer,
  challenge: challengeReducer,
  teamMembers: teamMembersReducer,
  reviewDiscrepancies: reviewDiscrepanciesReducer,
  challengesWithReviewDiscrepancies: challengesWithReviewDiscrepanciesReducer,
  invitesCsv: invitesCsvReducer,
  tasksCsv: tasksCsvReducer,
  activities: activitiesReducer,
  auditLogs: auditLogsReducer,
  eventSubscriptions: eventSubscriptionsReducer,
  checkInStats: checkInStatsReducer,
  activityTypesCsv: activityTypesCsvReducer,
  environmentDetails: environmentReducer,
  learningStats: learningStatsReducer,
  learningPracticalChallenges: learningPracticalChallengesReducer,
  learningEnrolment: learningEnrolmentReducer,
  learningCourseSpecs: learningCoursesReducer,
  accreditationRoles: accreditationRolesReducer,
  accreditationSpecs: accreditationSpecsReducer,
  accreditations: accreditationsReducer,
  roleAssignments: assignmentsReducer,
  compliances: compliancesReducer,
  communicationGroups: communicationGroupsReducer,
  accreditationRoleConditions: accreditationRoleConditionsReducer,
});

const resettableRootReducer = (
  state: ReturnType<typeof allReducers> | undefined,
  action: AnyAction
): ReturnType<typeof allReducers> => {
  if (action.type === resetStore.type) {
    return allReducers(undefined, action);
  }

  return allReducers(state, action);
};

export const resetStore = createAction("store/reset");

export const store = configureStore({
  reducer: resettableRootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
