import React, { useEffect } from "react";

import styled from "@emotion/styled";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { getTeam } from "../../slices/teamsSlice";
import useAppSelector from "../../../../hooks/useAppSelector";
import { RootState } from "../../../../redux/store";
import BasicInfoTab from "./BasicInfoTab";
import TeamMembersTab from "./TeamMembersTab";
import queryString from "query-string";
import { EditTeamQueryParams, TeamCapabilities } from "../../models/teams";
import { LoadingStatus } from "../../../../models/loadingStatus";
import CheckInNotificationTab from "./CheckInNotificationTab";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";
import { useCurrentOrg } from "../../../user/hooks/userHooks";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function EditTeam() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentOrg = useCurrentOrg();
  const { defaultTab } = queryString.parse(
    location.search
  ) as EditTeamQueryParams;

  const getDefaultTabValue = (defaultTab: string | undefined) => {
    switch (defaultTab) {
      case "team-members":
        return 1;
      case "event-subscriptions":
        return 2;
      default:
        return 0;
    }
  };

  const [tabValue, setTabValue] = React.useState(
    getDefaultTabValue(defaultTab)
  );
  const team = useAppSelector((state: RootState) => state.teams.team);
  const teamStatus = useAppSelector((state: RootState) => state.teams.status);

  useEffect(() => {
    if (id) {
      dispatch(getTeam(id));
    }
  }, [id, dispatch]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (!id || teamStatus !== LoadingStatus.succeeded) {
    return <LoadingStatusIndicator loadingStatuses={[teamStatus]} />;
  }

  return (
    <>
      <Helmet title="Edit team" data-cy="manageTeamsPage" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        data-cy="editTeamHeader"
      >
        {`Edit Team - ${team?.name}`}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Administration</Typography>
        <Link component={NavLink} to="/teams/manage">
          Teams
        </Link>
        <Typography>Edit team</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Box>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Basic Info" data-cy="basicInfoTab" />
          <Tab label="Team Members" data-cy="teamMembersTab" />
          {currentOrg?.capabilities?.find((e) => e === "checkIns") &&
            team?.capabilities?.find(
              (e) => e === TeamCapabilities.CheckIns
            ) && (
              <Tab
                label="Check-in notifications"
                data-cy="checkInNotificationsTab"
              />
            )}
        </Tabs>
      </Box>

      <BasicInfoTab team={team!} tabValue={tabValue} />
      <TeamMembersTab teamId={id!} tabValue={tabValue} />
      <CheckInNotificationTab teamId={id!} tabValue={tabValue} />
    </>
  );
}

export default EditTeam;
