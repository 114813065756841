import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import {
  CreateAccreditationRoleRequest,
  QueryAccreditationRoleRequest,
  UpdateAccreditationRoleRequest,
  createAccreditationRoleAsync,
  getAccreditationRoleAsync,
  queryAccreditationRolesAsync,
  updateAccreditationRoleAsync,
} from "../services/accreditationRoleService";
import { AccreditationRole } from "../models/role";

export const queryAccreditationRoles = createAsyncThunk(
  "accreditation-roles/queryAccreditationRoles",
  async (request: QueryAccreditationRoleRequest, { rejectWithValue }) => {
    try {
      const queryAccreditationRolesResponse =
        await queryAccreditationRolesAsync(request);

      return queryAccreditationRolesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const getAccreditationRole = createAsyncThunk(
  "accreditation-roles/getAccreditationRole",
  async (id: string, { rejectWithValue }) => {
    try {
      const getAccreditationRolesResponse = await getAccreditationRoleAsync(id);

      return getAccreditationRolesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const updateAccreditationRole = createAsyncThunk(
  "accreditation-roles/updateAccreditationRole",
  async (request: UpdateAccreditationRoleRequest, { rejectWithValue }) => {
    try {
      await updateAccreditationRoleAsync(request);
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const createAccreditationRole = createAsyncThunk(
  "accreditation-roles/createAccreditationRole",
  async (request: CreateAccreditationRoleRequest, { rejectWithValue }) => {
    try {
      const createAccreditationRolesResponse =
        await createAccreditationRoleAsync(request);

      return createAccreditationRolesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface AccreditationRolesState {
  accreditationRoles: AccreditationRole[];
  accreditationRole?: AccreditationRole;
  getAccreditationRoleStatus: LoadingStatus;
  status: LoadingStatus;
  error: string;
}

const initialState: AccreditationRolesState = {
  accreditationRoles: [],
  accreditationRole: undefined,
  status: LoadingStatus.idle,
  getAccreditationRoleStatus: LoadingStatus.idle,
  error: "",
};

const accreditationRolesSlice = createSlice({
  name: "accreditationRoles",
  initialState,
  reducers: {
    resetAccreditationRolesStatus: (state) => {
      return {
        ...state,
        status: LoadingStatus.idle,
      };
    },
    clearAccreditationRole: (state) => {
      return {
        ...state,
        getAccreditationRoleStatus: LoadingStatus.idle,
        accreditationRole: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryAccreditationRoles.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(queryAccreditationRoles.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditationRoles: action.payload.data,
      };
    });
    builder.addCase(queryAccreditationRoles.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        accreditationRoles: [],
        error: "Failed to get accreditation roles.",
      };
    });

    builder.addCase(getAccreditationRole.pending, (state) => {
      return {
        ...state,
        getAccreditationRoleStatus: LoadingStatus.loading,
      };
    });

    builder.addCase(getAccreditationRole.fulfilled, (state, action) => {
      return {
        ...state,
        getAccreditationRoleStatus: LoadingStatus.succeeded,
        accreditationRole: action.payload.data,
      };
    });
    builder.addCase(getAccreditationRole.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        getAccreditationRoleStatus: LoadingStatus.failed,
        error: "Failed to get accreditation role.",
      };
    });

    builder.addCase(updateAccreditationRole.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(updateAccreditationRole.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(updateAccreditationRole.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to update accreditation role.",
      };
    });

    builder.addCase(createAccreditationRole.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(createAccreditationRole.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditationRole: action.payload.data,
      };
    });
    builder.addCase(createAccreditationRole.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to create accreditation role.",
      };
    });
  },
});

export const { resetAccreditationRolesStatus, clearAccreditationRole } =
  accreditationRolesSlice.actions;
export default accreditationRolesSlice.reducer;
