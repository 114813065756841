import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  QueryAccreditationComplianceDetailsRequest,
  QueryAccreditationCompliancesRequest,
  QueryRoleCompliancesRequest,
  queryAccreditationComplianceDetailsAsync,
  queryAccreditationCompliancesAsync,
  queryRoleCompliancesAsync,
} from "../services/compliancesService";
import {
  AccreditationCompliance,
  AccreditationComplianceDetail,
  RoleCompliance,
} from "../models/compliance";
import { LoadingStatus } from "../../../models/loadingStatus";

export const queryAccreditationCompliances = createAsyncThunk(
  "compliances/queryAccreditationCompliances",
  async (
    request: QueryAccreditationCompliancesRequest,
    { rejectWithValue }
  ) => {
    try {
      const queryAccreditationRolesResponse =
        await queryAccreditationCompliancesAsync(request);

      return queryAccreditationRolesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const queryRoleCompliances = createAsyncThunk(
  "compliances/queryRoleCompliances",
  async (request: QueryRoleCompliancesRequest, { rejectWithValue }) => {
    try {
      const queryRoleCompaliancesResponse = await queryRoleCompliancesAsync(
        request
      );

      return queryRoleCompaliancesResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const queryAccreditationComplianceDetails = createAsyncThunk(
  "compliances/queryAccreditationComplianceDetails",
  async (
    request: QueryAccreditationComplianceDetailsRequest,
    { rejectWithValue }
  ) => {
    try {
      const queryAccreditationCompalianceDetailsResponse =
        await queryAccreditationComplianceDetailsAsync(request);

      return queryAccreditationCompalianceDetailsResponse.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface CompliancesState {
  accreditationCompliances: AccreditationCompliance[];
  roleCompliances: RoleCompliance[];
  accreditationComplianceDetails: AccreditationComplianceDetail[];
  complianceDetailsStatus: LoadingStatus;
  status: LoadingStatus;
  error: string;
}

const initialState: CompliancesState = {
  accreditationCompliances: [],
  roleCompliances: [],
  accreditationComplianceDetails: [],
  complianceDetailsStatus: LoadingStatus.idle,
  status: LoadingStatus.idle,
  error: "",
};

const compliancesSlice = createSlice({
  name: "compliances",
  initialState,
  reducers: {
    clearAccreditationCompliance: (state) => {
      return {
        ...state,
        accreditationCompliances: initialState.accreditationCompliances,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryAccreditationCompliances.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(
      queryAccreditationCompliances.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          accreditationCompliances: action.payload.data,
        };
      }
    );
    builder.addCase(queryAccreditationCompliances.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        accreditationCompliances: [],
        error: "Failed to get accreditations compliance.",
      };
    });

    builder.addCase(queryRoleCompliances.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(queryRoleCompliances.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        roleCompliances: action.payload.data,
      };
    });
    builder.addCase(queryRoleCompliances.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        roleCompliances: [],
        error: "Failed to get role compliances.",
      };
    });

    builder.addCase(queryAccreditationComplianceDetails.pending, (state) => {
      return {
        ...state,
        complianceDetailsStatus: LoadingStatus.loading,
      };
    });
    builder.addCase(
      queryAccreditationComplianceDetails.fulfilled,
      (state, action) => {
        return {
          ...state,
          complianceDetailsStatus: LoadingStatus.succeeded,
          accreditationComplianceDetails: action.payload.data,
        };
      }
    );
    builder.addCase(queryAccreditationComplianceDetails.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        complianceDetailsStatus: LoadingStatus.failed,
        accreditationComplianceDetails: [],
        error: "Failed to get accreditation compliance details.",
      };
    });
  },
});

export const { clearAccreditationCompliance } = compliancesSlice.actions;
export default compliancesSlice.reducer;
