import { Card as MuiCard } from "@mui/material";
import { Team } from "../../models/teams";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import EditTeamForm from "./EditTeamForm";

const Card = styled(MuiCard)(spacing);

type BasicInfoTabProps = {
  team: Team;
  tabValue: number;
};

function BasicInfoTab({ team, tabValue }: BasicInfoTabProps) {
  return (
    <>
      {tabValue === 0 ? (
        <Card mt={4}>
          <EditTeamForm team={team} type="update" />
        </Card>
      ) : null}
    </>
  );
}

export default BasicInfoTab;
