import React from "react";
import { useCurrentOrg } from "../../../user/hooks/userHooks";
import NavigateToUserHome from "../../../../components/NavigateToUserHome";

interface FeatureFlagGuardProps {
  children: React.ReactNode;
}

function CanUseTemplateLibraryGuard({ children }: FeatureFlagGuardProps) {
  const currentOrg = useCurrentOrg();

  if (!currentOrg?.featureFlags?.canUseChallengeLibrary) {
    return <NavigateToUserHome />;
  }

  return <>{children}</>;
}

export default CanUseTemplateLibraryGuard;
