import { useEffect, useState } from "react";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import { RootState } from "../../../../redux/store";
import { queryEventSubscriptions } from "../../slices/eventSubscriptionsSlice";
import { LoadingStatus } from "../../../../models/loadingStatus";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  Link,
  Paper,
  Button as MuiButton,
} from "@mui/material";
import {
  EventSubscription,
  EventSubscriptionType,
} from "../../models/eventSubscriptions";
import { useNavigate } from "react-router-dom";
import { getCurrentOrgId } from "../../../../contexts/AmplifyContext";
import { getTextForAlertLevel } from "../../../checkIns/utils/activityUtils";
import { useSnackbar } from "notistack";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import AgGridTable from "../../../../components/AgGridTable";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Modal from "../../../../components/Modal";
import { deleteEventSubscription } from "../../services/eventSubscriptionsService";
import LoadingStatusIndicator from "../../../../components/LoadingStatusIndicator";
import CommunicationGroupDetailsModal from "./CommunicationGroupDetailsModal";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Button = styled(MuiButton)(spacing);

type CheckInNotificationTabProps = {
  teamId: string;
  tabValue: number;
};

function CheckInNotificationTab({
  teamId,
  tabValue,
}: CheckInNotificationTabProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentOrgId = getCurrentOrgId();
  const [
    showDeleteEventSubscriptionModal,
    setShowDeleteEventSubscriptionModal,
  ] = useState<boolean>(false);
  const [selectedEventSubscriptionId, setSelectedEventSubscriptionId] =
    useState<string>("");

  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [showGroupDetailsModal, setShowGroupDetailsModal] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const eventSubscriptions = useAppSelector(
    (state: RootState) => state.eventSubscriptions.eventSubscriptions
  );
  const eventSubscriptionsStatus = useAppSelector(
    (state: RootState) => state.eventSubscriptions.status
  );

  const queryEventSubscriptionsWrapper = (
    currentOrgId: string | undefined,
    teamId: string
  ) => {
    if (currentOrgId && teamId) {
      dispatch(
        queryEventSubscriptions({
          orgId: currentOrgId,
          teamId: teamId,
          event: EventSubscriptionType.CheckInAlert,
        })
      );
    }
  };

  useEffect(() => {
    queryEventSubscriptionsWrapper(currentOrgId, teamId);
  }, [currentOrgId, teamId, dispatch]);

  const deleteSubscription = async (subscriptionId: string) => {
    try {
      await deleteEventSubscription(subscriptionId);

      queryEventSubscriptionsWrapper(currentOrgId, teamId);

      const snackbarKey = enqueueSnackbar(`Even subscription deleted`, {
        variant: "success",
        onClick: () => closeSnackbar(snackbarKey),
      });
    } catch (error: any) {
      console.warn(error);
      const message =
        error.error?.response?.data?.meta?.errorMessage ||
        "Something went wrong";

      const snackbarKey = enqueueSnackbar(message, {
        variant: "error",
        onClick: () => closeSnackbar(snackbarKey),
      });
    }
  };

  const closeDeleteEventSubscriptionModal = () => {
    setShowDeleteEventSubscriptionModal(false);
  };

  const onDeleteEventSubscriptionButtonClicked = () => {
    deleteSubscription(selectedEventSubscriptionId);
    setShowDeleteEventSubscriptionModal(false);
  };

  const columns = [
    {
      field: "communicationGroupName",
      headerName: "Communication group",
      sortable: true,
      flex: 3,
      cellRenderer: (props: ICellRendererParams<EventSubscription, string>) => {
        return (
          <Link
            className="visiblyLink"
            onClick={() => {
              setSelectedGroupId(props.data?.communicationGroupId);
              setShowGroupDetailsModal(true);
            }}
          >
            {props.value}
          </Link>
        );
      },
    },
    {
      field: "options",
      headerName: "Alert Level",
      sort: "asc",
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA.alertLevel === valueB.alertLevel) return 0;
        return valueA.alertLevel > valueB.alertLevel ? 1 : -1;
      },
      flex: 3,
      cellRenderer: (props: ICellRendererParams<string>) => {
        if (props.value.alertLevel) {
          return getTextForAlertLevel(props.value.alertLevel);
        }
        return "";
      },
    },
  ] as ColDef[];

  if (eventSubscriptionsStatus !== LoadingStatus.succeeded) {
    return (
      <LoadingStatusIndicator loadingStatuses={[eventSubscriptionsStatus]} />
    );
  }

  return (
    <>
      <Modal
        showModal={showDeleteEventSubscriptionModal}
        headerText={"Delete event subscription confirmation"}
        onClose={closeDeleteEventSubscriptionModal}
        bodyText={"Are you sure you want to delete this event subscription?"}
        buttons={[
          {
            text: "Delete",
            onClick: onDeleteEventSubscriptionButtonClicked,
          },
          {
            text: "Close",
            onClick: closeDeleteEventSubscriptionModal,
          },
        ]}
      />
      {selectedGroupId && (
        <CommunicationGroupDetailsModal
          show={showGroupDetailsModal}
          id={selectedGroupId!}
          onClose={() => {
            setShowGroupDetailsModal(false);
          }}
        />
      )}
      {tabValue === 2 ? (
        <Card mt={4} mb={6}>
          <CardContent p={1}>
            <Grid container>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <Button
                  m={4}
                  variant="contained"
                  data-cy="addEventSubscriptionButton"
                  onClick={() =>
                    navigate(`/teams/${teamId}/event-subscription/create`)
                  }
                >
                  Create subscription
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Grid style={{ height: "60vh", width: "100%" }}>
                    <AgGridTable
                      data={eventSubscriptions!}
                      columns={columns}
                      dataCyTag="challengeSetTable"
                      menu={{
                        menuItems: (row) => [
                          {
                            label: "Edit",
                            onClick: () => {
                              navigate(
                                `/teams/${teamId}/event-subscription/edit/${row.id}`
                              );
                            },
                            icon: "edit",
                            testingClassName: "editEventSubscription",
                          },
                          {
                            label: "Delete",
                            onClick: () => {
                              setShowDeleteEventSubscriptionModal(true);
                              setSelectedEventSubscriptionId(row.id);
                            },
                            icon: "cross",
                            testingClassName: "deleteEventSubscription",
                          },
                        ],
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}

export default CheckInNotificationTab;
