import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EventSubscriptionsQueryRequest } from "../services/eventSubscriptionsService";
import { EventSubscription } from "../models/eventSubscriptions";
import { LoadingStatus } from "../../../models/loadingStatus";
import * as eventSubscriptionService from "../services/eventSubscriptionsService";

export const queryEventSubscriptions = createAsyncThunk(
  "event-subscriptions/query",
  async (request: EventSubscriptionsQueryRequest, { rejectWithValue }) => {
    try {
      var result = await eventSubscriptionService.queryEventSubscriptions(
        request
      );

      return result.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

export const getEventSubscription = createAsyncThunk(
  "event-subscriptions/get",
  async (request: string, { rejectWithValue }) => {
    try {
      var result = await eventSubscriptionService.getEventSubscription(request);

      return result.data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

interface EventSubscriptionsState {
  eventSubscriptions: EventSubscription[];
  eventSubscription?: EventSubscription;
  status: LoadingStatus;
  error: "";
}

const initialState: EventSubscriptionsState = {
  eventSubscriptions: [],
  status: LoadingStatus.idle,
  error: "",
};

const eventSubscriptionsSlice = createSlice({
  name: "eventSubscriptions",
  initialState,
  reducers: {
    clearEventSubscription: (state) => {
      return {
        ...state,
        eventSubscription: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryEventSubscriptions.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryEventSubscriptions.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        eventSubscriptions: action.payload.data,
      };
    });
    builder.addCase(queryEventSubscriptions.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        eventSubscriptions: [],
      };
    });

    builder.addCase(getEventSubscription.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getEventSubscription.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        eventSubscription: action.payload.data,
      };
    });
    builder.addCase(getEventSubscription.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
  },
});

export const { clearEventSubscription } = eventSubscriptionsSlice.actions;

export default eventSubscriptionsSlice.reducer;
