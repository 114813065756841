import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../models/loadingStatus";
import { ChallengeLibraryItem } from "../models/challengeLibrary";
import * as challengeLibraryService from "../services/challengeLibraryService";

type GetChallengeLibraryRequest = {
  orgId: string;
  taxonomy?: string;
};

export const getChallengeLibrary = createAsyncThunk(
  "challenge-library/getChallengeLibraryList",
  async (request: GetChallengeLibraryRequest, { rejectWithValue }) => {
    try {
      return (
        await challengeLibraryService.getChallengeLibrary(
          request.orgId,
          request.taxonomy
        )
      ).data;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

type ChallengeLibraryState = {
  challengeLibraryItems: ChallengeLibraryItem[];
  status: LoadingStatus;
  error: string;
};

const initialState: ChallengeLibraryState = {
  challengeLibraryItems: [],
  status: LoadingStatus.idle,
  error: "",
};

const challengesLibraryTemplateSlice = createSlice({
  name: "challengeLibrary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChallengeLibrary.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getChallengeLibrary.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challengeLibraryItems: action.payload.data,
      };
    });

    builder.addCase(getChallengeLibrary.rejected, (state) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: "Failed to get challengeInstances library list",
      };
    });
  },
});

export default challengesLibraryTemplateSlice.reducer;
