export interface Team {
  id: string;
  membershipCount: number;
  orgId: string;
  name: string;
  description?: string;
  capabilities?: TeamCapabilities[];
}

export type EditTeamQueryParams = {
  defaultTab?: "team-members" | "edit-team";
};

export enum TeamCapabilities {
  Skills = "skills",
  CheckIns = "checkIns",
}
