import { Navigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";

interface CanViewPracticalReviewsRouteGuardProps {
  children: JSX.Element;
}

function CanViewPracticalReviewsRouteGuard({
  children,
}: CanViewPracticalReviewsRouteGuardProps) {
  const { isVisiblyPracticalReviewer } = useAuth();

  if (!isVisiblyPracticalReviewer) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}

export default CanViewPracticalReviewsRouteGuard;
